.form {
    &-paragraph {
        max-width: 840px;
    }

    &-badge {
        padding: 8px 14px;
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .icons {
            margin-top: 2px;
            margin-right: 0;
            width: 18px;
            height: 18px;
            min-width: 18px;
            font-size: 16px;
        }

        &-content {
            max-width: 960px;
            font-size: 14px;
            white-space: normal;
            text-align: left;
            line-height: 22px;
            letter-spacing: -0.24px;
        }

        &__title {
            font-weight: 600;
        }
        &__description {
            margin-top: 4px;
            padding-bottom: 16px;
        }
        &__collapse-btn {
            text-decoration: underline;
        }
    }

    &-result-block {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: $gray-200;

        &--error {
            background-color: $red-light;
        }

        &__title {
            padding-bottom: 16px;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
        }
        &__description {
            max-width: 700px;
            font-size: 16px;
            line-height: 22px;
        }
        &__logo {
            margin-top: 40px;
            max-width: 120px;
            height: 30px;
            img {
              display: block;
              object-fit: contain;
      
              @include size(100%);
            }
        }
    }

    &-input-block {
        padding: 16px;
        background-color: $gray-200;

        &-inner {
            max-width: 840px;
        }

        &__title {
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 600;
        }
        &__description {
            line-height: 22px;
        }
        ul {
            margin: 0;
            padding-left: 16px;
        }
        .form-group {
            .form-check {
                margin-top: 14px;
            }   
        }
    }

    &-photos {
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__item {
            display: flex;
            align-items: center;
            gap: 16px;
        }
        &__remove {
            font-size: 14px;
            font-weight: normal;
            color: $danger;
        }
    }
}
