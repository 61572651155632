// Color system
$white:                                               #fff !default;
$gray-100:                                            #fcfcfc !default;
$gray-200:                                            #F4F5F9 !default;
$gray-300:                                            #E5EBF4 !default;
$gray-400:                                            #9CAEC4 !default;
$gray-500:                                            #5F7895 !default;
$gray-600:                                            #3A5371 !default;
$gray-700:                                            #0A2F5A !default;
$gray-800:                                              null;
$gray-900:                                              null;
$black:                                               #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

//Blue
$initial:                                             #0055D3 !default;
$initial-light:                                         tint($initial, 80%) !default;
$initial-dark:                                          shade($initial, 15%) !default;

// Red
$red:                                                 #D82C0D !default;
$red-light:                                             #FFE9E8 !default;
$red-dark:                                              shade($red, 15%) !default;

// Orange
$orange:                                              #FFAC0C !default;
$orange-light:                                          tint($orange, 80%) !default;
$orange-dark:                                           shade($orange, 15%) !default;

// Yellow
$yellow:                                              #f8c02a !default;
$yellow-light:                                          tint($yellow, 80%) !default;
$yellow-dark:                                           shade($yellow, 15%) !default;

// Teal
$teal:                                                #31bfbf !default;
$teal-light:                                            tint($teal, 80%) !default;
$teal-dark:                                             shade($teal, 15%) !default;

// Blue
$blue:                                                #5BCDDA !default;
$blue-light:                                            tint($blue, 80%) !default;
$blue-dark:                                             shade($blue, 15%) !default;

// Purple
$purple:                                              #8670c5 !default;
$purple-light:                                          tint($purple, 80%) !default;
$purple-dark:                                           shade($purple, 15%) !default;

// Green
$green:                                               #29AE6F !default;
$green-light:                                           tint($green, 80%) !default;
$green-dark:                                            shade($green, 15%) !default;

// Pink
$pink:                                                #e83e8c !default;
$pink-light:                                            tint($pink, 80%) !default;
$pink-dark:                                             shade($pink, 15%) !default;

// Indigo
$indigo:                                              #6610f2 !default;
$indigo-light:                                          tint($indigo, 80%) !default;
$indigo-dark:                                           shade($indigo, 15%) !default;

$colors: () !default;
$colors: map-merge(
  (
    "initial":        $initial,
    "initial-light":  $initial-light,
    "initial-dark":   $initial-dark,
    "red":            $red,
    "red-light":      $red-light,
    "red-dark":       $red-dark,
    "orange":         $orange,
    "orange-light":   $orange-light,
    "orange-dark":    $orange-dark,
    "yellow":         $yellow,
    "yellow-light":   $yellow-light,
    "yellow-dark":    $yellow-dark,
    "teal":           $teal,
    "teal-light":     $teal-light,
    "teal-dark":      $teal-dark,
    "blue":           $blue,
    "blue-light":     $blue-light,
    "blue-dark":      $blue-dark,
    "purple":         $purple,
    "purple-light":   $purple-light,
    "purple-dark":    $purple-dark,
    "green":          $green,
    "green-light":    $green-light,
    "green-dark":     $green-dark,
    "pink":           $pink,
    "pink-light":     $pink-light,
    "pink-dark":      $pink-dark,
    "indigo":         $indigo,
    "indigo-light":   $indigo-light,
    "indigo-dark":    $indigo-dark,
  ),
  $colors
);

$primary-100:                                           null !default;
$primary-200:                                           null !default;
$primary-300:                                           var(--primary-lighten) !default;
$primary-400:                                           var(--primary-light) !default;
$primary-500:                                           var(--primary) !default;
$primary-600:                                           var(--primary-dark) !default;
$primary-700:                                           var(--primary-darken) !default;
$primary-800:                                           null !default;
$primary-900:                                           null !default;

$primary-text-color:                                    var(--primary-text-color) !default;

$theme-primary: () !default;
$theme-primary: map-merge(
  (
    "100": $primary-100,
    "200": $primary-200,
    "300": $primary-300,
    "400": $primary-400,
    "500": $primary-500,
    "600": $primary-600,
    "700": $primary-700,
    "800": $primary-800,
    "900": $primary-900,
  ),
  $theme-primary
);

$primary:                                               theme-primary() !default;
$secondary:                                             $gray-200 !default;
$success:                                               $green !default;
$info:                                                  $blue !default;
$warning:                                               $orange !default;
$danger:                                                $red !default;

// Without primary!
$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "initial":    $initial,
  ),
  $theme-colors
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:                              150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:                                         $black !default;
$yiq-text-light:                                        $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret:                                          true !default;
$enable-shadows:                                        false !default;
$enable-rounded:                                        true !default;
$enable-pointer-cursor-for-buttons:                     true !default;
$enable-transitions:                                    true !default;
$enable-grid-classes:                                   true !default;
$enable-prefers-reduced-motion-media-query:             true !default;
$enable-validation-icons:                               true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer:                                                16px !default;

// Body
//
// Settings for the `<body>` element.
$body-bg:                                               $white !default;
$body-color:                                            $gray-700 !default;

// Links
//
// Style anchor elements.

$link-color:                                            theme-primary() !default;
$link-decoration:                                       none !default;
$link-hover-color:                                      theme-primary("600") !default;
$link-hover-decoration:                                 underline !default;
$link-hover-background:                                 theme-primary("400") !default;
$link-active-background:                                transparent !default;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage:               7% !default;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:                               $spacer !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$breakpoints-menu: (
  xs: 0,
  md: 768px,
  lg: 1024px,
  xl: 1364px,
);

$breakpoints-drawer: (
  lg: 1478px,
  xl: 1700px,
);

$grid-breakpoints:                                      $breakpoints !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns:                                          12 !default;
$grid-gutter-width:                                     12px !default;
$grid-row-columns:                                      6 !default;

// Components
//
// Define common padding and border radius sizes and more.
$line-height-sm:                                        1.25 !default;
$line-height-lg:                                        1.45 !default;

$border-width:                                          1px !default;
$border-color:                                          $gray-300 !default;
$border:                                                $border-width solid $border-color !default;

$disabled-color:                                        $gray-400;

$border-radius:                                         4px;
$border-radius-sm:                                      2px;
$border-radius-lg:                                      8px;

$component-active-color:                                $white !default;
$component-active-bg:                                   theme-color("primary") !default;

$caret-width:                                           .3em !default;
$caret-vertical-align:                                  $caret-width * 0.85 !default;
$caret-spacing:                                         $caret-width * 0.85 !default;

$transition-base:                                       all 0.3s ease-in-out !default;
$transition-fade:                                       opacity 0.15s linear !default;
$transition-collapse:                                   height 0.35s ease !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:                                "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:                                 SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:                                      $font-family-sans-serif !default;

$font-size-l:                                           18px !default;
$font-size-m:                                           16px !default;
$font-size-s:                                           14px !default;
$font-size-xs:                                          12px !default;

$font-weight-lighter:                                   lighter !default;
$font-weight-light:                                     300 !default;
$font-weight-normal:                                    400 !default;
$font-weight-medium:                                    500 !default;
$font-weight-semi-bold:                                 600 !default;
$font-weight-bold:                                      bold !default;
$font-weight-bolder:                                    bolder !default;

$line-height-base:                                      1.43 !default;
$font-weight-base:                                      $font-weight-normal !default;
$font-size-base:                                        $font-size-s !default;

$h1-font-size:                                          28px !default;
$h2-font-size:                                          24px !default;
$h3-font-size:                                          $font-size-l !default;
$h4-font-size:                                          $font-size-m !default;
$h5-font-size:                                          $font-size-s !default;

$headings-margin-bottom:                                $spacer !default;
$headings-font-family:                                  null !default;
$headings-font-weight:                                  $font-weight-semi-bold !default;
$headings-line-height:                                  1.2 !default;
$headings-color:                                        $gray-700 !default;

$small-font-size:                                       80% !default;

$text-muted:                                            $gray-400 !default;

$hr-border-color:                                       $border-color !default;
$hr-border-width:                                       $border-width !default;

$dt-font-weight:                                        $font-weight-bold !default;

$hr-margin-y:                                           $spacer !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding:                                    $spacer !default;
$table-cell-padding-sm:                                 $spacer / 2 !default;

$table-color:                                           $body-color !default;
$table-bg:                                              null !default;
$table-accent-bg:                                       theme-primary("300") !default;
$table-hover-color:                                     $table-color !default;
$table-hover-bg:                                        theme-primary("300") !default;

$table-border-width:                                    $border-width !default;
$table-border-color:                                    $border-color !default;

$table-head-bg:                                         null !default;
$table-head-color:                                      $gray-500 !default;
$table-th-font-weight:                                  null !default;

$table-striped-order:                                   odd !default;

$table-caption-color:                                   $text-muted !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y:                                   5px !default;
$input-btn-padding-x:                                   12px !default;
$input-btn-font-family:                                 $font-family-base !default;
$input-btn-line-height:                                 $line-height-base !default;
$input-btn-font-size:                                   $font-size-base !default;

$input-btn-focus-width:                                 .2rem !default;

$input-btn-padding-y-lg:                                11px !default;
$input-btn-padding-x-lg:                                16px !default;
$input-btn-font-size-lg:                                $font-size-base !default;
$input-btn-line-height-lg:                              $line-height-lg !default;

$input-btn-padding-y-sm:                                3px !default;
$input-btn-padding-x-sm:                                10px !default;
$input-btn-font-size-sm:                                $font-size-xs !default;
$input-btn-line-height-sm:                              $line-height-sm !default;

$input-btn-border-width:                                $border-width !default;


// Buttons
$btn-padding-y:                                         $input-btn-padding-y !default;
$btn-padding-x:                                         $input-btn-padding-x * 1.2 !default;
$btn-font-family:                                       $input-btn-font-family !default;
$btn-font-size:                                         $input-btn-font-size !default;
$btn-line-height:                                       $input-btn-line-height !default;
$btn-white-space:                                       null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-lg:                                      $input-btn-padding-y-lg  !default;
$btn-padding-x-lg:                                      $input-btn-padding-x-lg + 8 !default;
$btn-font-size-lg:                                      $input-btn-font-size-lg !default;
$btn-line-height-lg:                                    $input-btn-line-height-lg !default;

$btn-padding-y-sm:                                      $input-btn-padding-y-sm !default;
$btn-padding-x-sm:                                      $input-btn-padding-x-sm !default;
$btn-font-size-sm:                                      $input-btn-font-size-sm !default;
$btn-line-height-sm:                                    $input-btn-line-height-sm !default;

$btn-border-width:                                      $input-btn-border-width !default;

$btn-font-weight:                                       $font-weight-medium !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-disabled-opacity:                                  0.4 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:                               null !default;

$btn-block-spacing-y:                                   16px !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:                                     10000px !default;
$btn-border-radius-lg:                                  10000px !default;
$btn-border-radius-sm:                                  10000px !default;

$btn-transition:                                        color .15s ease-in-out,
                                                        background-color .15s ease-in-out,
                                                        border-color .15s ease-in-out,
                                                        box-shadow .15s ease-in-out !default;
// Icon Button
$btn-icon-color:                                        $gray-600;
$btn-icon-border-radius:                                50%;
$btn-icon-hover-background:                             $gray-200;
$btn-icon-hover-color:                                  $btn-icon-color;
$btn-icon-active-background:                            transparent;
$btn-icon-active-color:                                 $gray-700;

// Inputs
$input-padding-y:                                       $input-btn-padding-y !default;
$input-padding-x:                                       $input-btn-padding-x !default;
$input-font-family:                                     $input-btn-font-family !default;
$input-font-size:                                       $input-btn-font-size !default;
$input-font-weight:                                     $font-weight-base !default;
$input-line-height:                                     $input-btn-line-height !default;

$input-padding-y-lg:                                    $input-btn-padding-y-lg !default;
$input-padding-x-lg:                                    $input-btn-padding-x-lg !default;
$input-font-size-lg:                                    $input-btn-font-size-lg !default;
$input-line-height-lg:                                  $input-btn-line-height-lg !default;

$input-bg:                                              $white !default;
$input-disabled-bg:                                     $white !default;
$input-disabled-opacity:                                0.6 !default;

$input-color:                                           $gray-700 !default;
$input-border-color:                                    $border-color !default;
$input-border-width:                                    $input-btn-border-width !default;

$input-border-radius:                                   4px !default;
$input-border-radius-lg:                                4px !default;

$input-focus-bg:                                        $input-bg !default;
$input-focus-border-color:                              $component-active-bg !default;
$input-focus-color:                                     $input-color !default;

$input-placeholder-color:                               $gray-500 !default;
$input-plaintext-color:                                 $body-color !default;

$input-height-border:                                   $input-border-width * 2 !default;
$input-height-inner:                                    add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:                               add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:                            add($input-line-height * .25em, $input-padding-y / 2) !default;

$input-height:                                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-lg:                                       add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:                                      border-color .15s ease-in-out !default;

// Forms
$label-color:                                           $gray-500;
$label-font-size:                                       $font-size-xs;
$label-margin-bottom:                                   4px !default;

// Form Text
$form-text-margin-top:                                  12px !default;
$form-text-font-size:                                   $font-size-s !default;
$form-text-font-style:                                  null !default;
$form-text-font-weight:                                 null !default;
$form-text-color:                                       $text-muted !default;

$form-group-margin-bottom:                              $spacer !default;

$input-group-addon-border-color:                        $input-border-color !default;
$input-group-addon-color:                               $input-color !default;
$input-group-addon-bg:                                  $white !default;

// Form Floating
$form-floating-height:                                  add(42px, $input-height-border);
$form-floating-line-height:                             1.25;
$form-floating-label-font-size:                         $input-btn-font-size;
$form-floating-padding-x:                               $input-padding-x;
$form-floating-padding-y:                               12px;
$form-floating-input-padding-t:                         20px;
$form-floating-input-padding-b:                         6px;
$form-floating-label-color:                             $gray-500;
$form-floating-label-transform:                         scale(.85) translateY(-.5rem) translateX(.15rem);
$form-floating-transition:                              opacity .1s ease-in-out, transform .1s ease-in-out;

// Default Select
$form-select-padding-y:                                 $input-padding-y;
$form-select-padding-x:                                 $input-padding-x;
$form-select-font-family:                               $input-font-family;
$form-select-font-size:                                 $input-font-size;
$form-select-indicator-padding:                         $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight:                               $input-font-weight;
$form-select-line-height:                               $input-line-height;
$form-select-color:                                     $input-color;
$form-select-bg:                                        $input-bg;
$form-select-disabled-bg:                               $input-disabled-bg;
$form-select-bg-position:                               right $form-select-padding-x center;
$form-select-bg-size:                                   16px 12px; // In pixels because image dimensions
$form-select-indicator-color:                           $gray-400;
$form-select-indicator:                                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-end:                 $form-select-padding-x * 2.5 + $form-select-indicator-padding;
$form-select-feedback-icon-position:                    center right $form-select-indicator-padding;
$form-select-feedback-icon-size:                        $input-height-inner-half $input-height-inner-half;

$form-select-border-width:                              $input-border-width;
$form-select-border-color:                              $input-border-color;
$form-select-border-radius:                             4px;

$form-select-focus-border-color:                        $input-focus-border-color;

$form-select-padding-y-lg:                              $input-padding-y-lg;
$form-select-padding-x-lg:                              $input-padding-x-lg;
$form-select-font-size-lg:                              $input-font-size-lg;

$form-select-transition:                                $input-transition;

// Checkbox & Radio
$form-check-input-width:                                16px;
$form-check-min-height:                                 $font-size-base * $line-height-base;
$form-check-padding-start:                              $form-check-input-width + 8px;
$form-check-margin-bottom:                              2px;
$form-check-label-color:                                inherit !default;
$form-check-label-cursor:                               pointer;
$form-check-transition:                                 null;

$form-check-input-active-filter:                        theme-primary('400');

$form-check-input-bg:                                   $input-bg;
$form-check-input-border:                               1px solid $gray-400;
$form-check-input-border-radius:                        2px;
$form-check-radio-border-radius:                        50%;

$form-check-input-checked-color:                        $component-active-color;
$form-check-input-checked-bg-color:                     $component-active-bg;
$form-check-input-checked-border-color:                 $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image:                     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'><path fill='#{$form-check-input-checked-color}' d='M12.579 23.686l-6.933-6.973c-0.417-0.419-0.417-1.098 0-1.517l1.508-1.517c0.417-0.419 1.092-0.419 1.508 0l4.671 4.697 10.004-10.061c0.417-0.419 1.092-0.419 1.509 0l1.508 1.517c0.417 0.419 0.417 1.098 0 1.517l-12.267 12.337c-0.417 0.419-1.092 0.419-1.509-0z' /></svg>");
$form-check-radio-checked-bg-image:                     url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2.5' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color:                  $component-active-color;
$form-check-input-indeterminate-bg-color:               $component-active-bg;
$form-check-input-indeterminate-border-color:           $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-check-input-disabled-opacity:                     .5;
$form-check-label-disabled-opacity:                     $form-check-input-disabled-opacity;
$form-check-btn-check-disabled-opacity:                 $btn-disabled-opacity;

$form-check-inline-margin-end:                          16px;

// Switch
$form-switch-color:                                     $component-active-color !default;
$form-switch-width:                                     38px !default;
$form-switch-padding-start:                             $form-switch-width + 8px !default;
$form-switch-bg:                                        $gray-300;
$form-switch-bg-hover:                                  $gray-300;
$form-switch-bg-image:                                  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3.5' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius:                             10px !default;
$form-switch-transition:                                background-position .15s ease-in-out,
                                                        background-color .15s ease-in-out,
                                                        border-color .15s ease-in-out !default;
$form-switch-checked-bg-position:                       right center !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
$zindex-dropdown:                                       1000 !default;
$zindex-sticky:                                         1020 !default;
$zindex-fixed:                                          1030 !default;
$zindex-modal-backdrop:                                 1040 !default;
$zindex-modal:                                          1050 !default;
$zindex-popover:                                        1060 !default;
$zindex-tooltip:                                        1070 !default;

$dropdown-min-width:                                    145px;
$dropdown-padding-x:                                    0;
$dropdown-padding-y:                                    8px;
$dropdown-spacer:                                       .125rem;
$dropdown-font-size:                                    $font-size-base;
$dropdown-color:                                        $body-color;
$dropdown-bg:                                           $white;
$dropdown-border-color:                                 $border-color;
$dropdown-border-radius:                                10px;
$dropdown-border-width:                                 $border-width;
$dropdown-inner-border-radius:                          subtract($dropdown-border-radius, $dropdown-border-width);
$dropdown-divider-bg:                                   $dropdown-border-color;
$dropdown-divider-margin-y:                             $spacer * .5;

$dropdown-link-color:                                   $body-color;
$dropdown-link-hover-color:                             $link-hover-color;
$dropdown-link-hover-bg:                                $link-hover-background;

$dropdown-link-active-color:                            $component-active-color;
$dropdown-link-active-bg:                               $component-active-bg;

$dropdown-link-disabled-color:                          $gray-400;

$dropdown-item-padding-y:                               $spacer * .25;
$dropdown-item-padding-x:                               $spacer;

$dropdown-header-color:                                 $gray-600;
$dropdown-header-padding:                               $dropdown-padding-y $dropdown-item-padding-x;

// Pagination

$pagination-padding-y:                                  1px !default;
$pagination-padding-x:                                  9px !default;

$pagination-color:                                      $body-color !default;
$pagination-bg:                                         $gray-200 !default;
$pagination-border-width:                               $border-width !default;
$pagination-border-radius:                              8px !default;
$pagination-margin-start:                               4px !default;
$pagination-border-color:                               $gray-200 !default;

$pagination-focus-color:                                $link-hover-color !default;
$pagination-focus-bg:                                   $gray-200 !default;
$pagination-focus-outline:                              0 !default;

$pagination-hover-color:                                $link-hover-color !default;
$pagination-hover-bg:                                   theme-primary('400') !default;
$pagination-hover-border-color:                         theme-primary('400') !default;

$pagination-active-color:                               theme-primary() !default;
$pagination-active-bg:                                  theme-primary('400') !default;
$pagination-active-border-color:                        theme-primary('400') !default;

$pagination-disabled-color:                             $body-color !default;
$pagination-disabled-bg:                                $gray-200 !default;
$pagination-disabled-border-color:                      $gray-200 !default;
$pagination-opacity:                                    0.2 !default;

$pagination-transition:                                 color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Spinners
$spinner-width:                                         32px !default;
$spinner-height:                                        $spinner-width !default;
$spinner-border-width:                                  .25em !default;

$spinner-width-sm:                                      16px !default;
$spinner-height-sm:                                     $spinner-width-sm !default;
$spinner-border-width-sm:                               .2em !default;

$drawer-width:                                          443px;
$drawer-background:                                     $gray-200;
$drawer-box-shadow:                                     0px 4px 4px rgba($black, 0.25), inset -1px 0px 0px $gray-300;
$drawer-horizontal-padding:                             20px;
$drawer-header-horizontal-gap:                          8px;



// Popovers

// scss-docs-start popover-variables
$popover-font-size:                 $font-size-base !default;
$popover-bg:                        $white !default;
$popover-max-width:                 276px !default;
$popover-border-width:              $border-width !default;
$popover-border-color:              $gray-300 !default;
$popover-border-radius:             $border-radius-lg !default;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) !default;
$popover-box-shadow:                $drawer-box-shadow !default;

$popover-header-bg:                 $gray-300 !default;
$popover-header-color:              $headings-color !default;
$popover-header-padding-y:          .5rem !default;
$popover-header-padding-x:          $spacer !default;

$popover-body-color:                $body-color !default;
$popover-body-padding-y:            $spacer !default;
$popover-body-padding-x:            $spacer !default;

$popover-arrow-width:               1rem !default;
$popover-arrow-height:              .5rem !default;
$popover-arrow-color:               $popover-bg !default;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) !default;
// scss-docs-end popover-variables

// Badges

$badge-font-size:                   $font-size-xs !default;
$badge-font-weight:                 $font-weight-normal !default;
$badge-padding-y:                   .33em !default;
$badge-padding-x:                   .33em !default;
$badge-border-radius:               $border-radius !default;

$badge-transition:                  $btn-transition !default;
$badge-focus-width:                 $input-btn-focus-width !default;

$badge-pill-padding-x:              .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius:          10rem !default;

$badge-colors: () !default;
$badge-colors: map-merge(
    (
      "primary":    (
        "bg": theme-primary("300"),
        "color": theme-primary("500"),
      ),
      "secondary":  (
        "bg": $secondary,
        "color": $gray-500,
      ),
      "success":  (
        "bg": $green-light,
        "color": $green,
      ),
      "info":(
        "bg": $blue-light,
        "color": $blue,
      )       ,
      "warning":    (
        "bg": $orange-light,
        "color": $orange,
      ),
      "danger":     (
        "bg": $red-light,
        "color": $red,
      ),
      "initial":     (
        "bg": $initial-light,
        "color": $initial,
      ),
    ),
    $badge-colors
);

$event-colors: $badge-colors;

// Modals

// Padding applied to the modal body
$modal-inner-padding:                                   20px !default;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:                           12px !default;

$modal-dialog-margin:                                   8px !default;
$modal-dialog-margin-y-sm-up:                           20px !default;

$modal-title-line-height:                               $line-height-base !default;

$modal-content-color:                                   null !default;
$modal-content-bg:                                      $white !default;
$modal-content-border-color:                            transparent !default;
$modal-content-border-width:                            $border-width !default;
$modal-content-border-radius:                           4px !default;
$modal-content-inner-border-radius:                     subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:                           0px 2px 8px rgba(0, 0, 0, 0.16) !default;
$modal-content-box-shadow-sm-up:                        0px 2px 8px rgba(0, 0, 0, 0.16) !default;

$modal-backdrop-bg:                                     $gray-700 !default;
$modal-backdrop-opacity:                                .2 !default;
$modal-header-border-color:                             transparent !default;
$modal-footer-border-color:                             $modal-header-border-color !default;
$modal-header-border-width:                             $modal-content-border-width !default;
$modal-footer-border-width:                             $modal-header-border-width !default;
$modal-header-padding-y:                                20px !default;
$modal-header-padding-x:                                20px !default;
$modal-header-padding:                                  $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                                              1140px !default;
$modal-lg:                                              800px !default;
$modal-md:                                              525px !default;
$modal-sm:                                              380px !default;

$modal-fade-transform:                                  translate(0, -50px) !default;
$modal-show-transform:                                  none !default;
$modal-transition:                                      transform .3s ease-out !default;
$modal-scale-transform:                                 scale(1.02) !default;
