.form-floating {
  position: relative;

  > .form-control,
  > .form-select {
    height: $form-floating-height;
    line-height: $form-floating-line-height;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; // allow textareas
    font-size: $form-floating-label-font-size;
    color: $input-placeholder-color;
    padding: $form-floating-padding-y $form-floating-padding-x;
    pointer-events: none;
    border: $input-border-width solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    @include transition($form-floating-transition);
  }

  // stylelint-disable no-duplicate-selectors
  > .form-control {
    padding: $form-floating-padding-y $form-floating-padding-x;

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }

    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: $form-floating-input-padding-t;
      padding-bottom: $form-floating-input-padding-b;
    }
  }

  > .form-select {
    padding-top: $form-floating-input-padding-t;
    padding-bottom: $form-floating-input-padding-b;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-select {
    ~ label {
      transform: $form-floating-label-transform;
      color: $form-floating-label-color;
    }
  }

  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      transform: $form-floating-label-transform;
      color: $form-floating-label-color;
    }
  }

  > .form-control:disabled,
  > .form-select:disabled {
    ~ label {
      opacity: $input-disabled-opacity;
    }
  }

  // stylelint-enable no-duplicate-selectors
}

.form-floating-label {
  > label {
    font-size: $font-size-xs;
    margin-bottom: 4px;
    font-weight: $font-weight-semi-bold;
    color: $gray-700;
  }
}
