.auth {
  padding: 24px 20px;
  overflow: auto;
  height: 100%;
  @extend .custom-scroll;

  &-logo {
    margin-bottom: 48px;
    text-align: center;

    img {
      width: 200px;
    }
  }

  &-title {
    margin-bottom: 20px;
    text-align: center;
  }

  &-content {
    max-width: 349px;
    margin: 0 auto;
    padding: 25px 25px;
    border: $border;
    border-radius: $border-radius;
    background-color: $white;

    @include media-breakpoint-down(sm, $breakpoints) {
      padding: 25px 16px;
    }
  }

  &-sign-in-switch {
    padding-left: 0;

    label {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &-description {
    color: $gray-400;
    text-align: center;
    margin-bottom: 24px;

    p {
      margin-bottom: 0;

      & + p {
        margin-top: $paragraph-margin-bottom;
      }
    }
  }

  &-resend-code {
    margin-bottom: 24px;

    .resend-title {
      font-size: 14px;
      cursor: pointer;
      color: theme-primary();
    }

    .resend-loading {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-phone {
    color: $black;
    font-size: 18px;
  }

  &-email {
    color: $black;
    font-size: 18px;
    overflow-wrap: break-word;

    @include media(">sm") {
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
  }

  &-recovery-code {
    cursor: pointer;
    margin-bottom: 24px;
    font-size: $h3-font-size;
    color: theme-primary();
    font-weight: $font-weight-bold;
    text-align: center;

    @include media(">sm") {
      display: flex;
      justify-content: center;
      white-space: nowrap;
    }
  }

  &-button-icon {
    .icons {
      @include size(20px);
      margin-left: 5px;
    }
  }

  &-wrap-checks {
    margin-bottom: 16px;
  }

  &-wrap-check {
    display: flex;
    align-items: center;

    .icons {
      font-size: 22px;
      margin-right: 8px;
    }

    & + .auth-wrap-check {
      margin-top: 6px;
    }
  }

  &-input-group-password {
    .input-group-append {
      right: 5px;
      margin-top: 0;
    }

    button:hover {
      background-color: $gray-200;
    }
  }

  &__forgot-password {
    margin-top: 16px;

    a {
      color: $gray-600;
    }
  }

  .form-group > .form-control:not(:last-child) {
    padding-right: 35px;
  }
}
