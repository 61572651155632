.form-control {
  display: block;
  width: 100%;
  height: $input-height;
  padding: $input-padding-y $input-padding-x;
  border: $input-border-width solid $input-border-color;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  font-family: $input-font-family;
  font-size: $input-font-size;
  background-color: $input-bg;
  background-clip: padding-box;
  color: $input-color;
  appearance: none; // Fix appearance for date inputs in Safari

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include border-radius($input-border-radius, 0);
  @include transition($input-transition);

  &[type="file"] {
    overflow: hidden; // prevent pseudo element button overlap

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    border-color: $input-focus-border-color;
    background-color: $input-focus-bg;
    color: $input-focus-color;
    outline: 0;
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Placeholder
  &::placeholder {
    color: inherit;

    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 0.5;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    border-color: $input-border-color;

    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: $input-disabled-opacity;
  }
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }

  label + & {
    margin-top: -$label-margin-bottom - 4;
  }
}



// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
.form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  line-height: $input-line-height-lg;
  font-size: $input-font-size-lg;
  height: $input-height-lg;

  @include border-radius($input-border-radius-lg);
}

// Make sure textareas don't shrink too much when resized
// https://github.com/twbs/bootstrap/pull/29124
// stylelint-disable selector-no-qualifying-type
textarea {
  &.form-control,
  &.form-control-lg {
    min-height: 80px;
  }
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  @at-root fieldset & {
    margin-bottom: $form-group-margin-bottom - 4;
  }

  &--no-margin {
    margin-bottom: unset;
  }
}

fieldset {
  padding: ($spacer / 2) 0 12px;
}

.form-control-code {
  letter-spacing: 0.25rem;
}
